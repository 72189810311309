import axios from 'axios';
import { clearTokenStorage, getTokenStorageService } from 'services/Auth';

const api = axios.create({
    baseURL: process.env.REACT_APP_BACK_PORT || 'http://localhost:3000',
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
    }
});

// Add a request interceptor
api.interceptors.request.use(function (config) {
    const token = getTokenStorageService();
    config.headers.Authorization = `Bearer ${token}`;

    return config;
});

api.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response.status === 403) {
            console.log('forbbiden');
            clearTokenStorage();
            window.location.href = '/login';
        }
    }
);

const mpApi = axios.create({
    baseURL: 'https://api.mercadopago.com/v1/',
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer'
    }
});

export { api, mpApi };
