import { api } from '../axios';

export const getAllRafflesApi = () => {
    return api.get(`/raffles/panel`);
};

export const getRaffleApi = (raffleId) => {
    return api.get(`/raffle/${raffleId}`);
};

export const saveRaffleApi = (raffle) => {
    return api.post(`/raffle`, raffle);
};

export const updateRaffleApi = (raffle) => {
    return api.put(`/raffle/${raffle._id}`, raffle);
};

export const deleteRaffleApi = (id) => {
    return api.delete(`/raffle/${id}`);
};

export const loadParticipantsApi = (body) => {
    return api.post('/slot', body);
};

export const addAwardApi = (raffleId, award) => {
    return api.put(`/raffles/award/${raffleId}`, award);
};

export const updateAwardApi = (raffleId, awardOrder, award) => {
    return api.put(`/raffle/${raffleId}/awards/${awardOrder}`, award);
};

export const uploadRaffleAwardsApi = (raffleId, awardOrder, body) => {
    return api.post(`/raffles/${raffleId}/awards/${awardOrder}/participant`, body);
};

export const uploadImageAwardApi = (raffleId, awardId, fileData) => {
    const formData = new FormData();
    formData.append('archivo', fileData); // Adjunta la imagen al FormData

    return api.post(`/raffles/${raffleId}/awards/${awardId}/image`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
};

export const uploadImageRaffleApi = (raffleId, fileData) => {
    const formData = new FormData();
    formData.append('archivo', fileData); // Adjunta la imagen al FormData

    return api.post(`/raffles/${raffleId}/image`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
};

export const notifyParticipantsApi = (raffleId) => {
    return api.post(`/raffles/${raffleId}/notify-participant`, {});
};
