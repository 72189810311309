import { Search } from '@mui/icons-material';
import {
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Tooltip
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useForm } from 'hooks/useForm';
import { useParticipant } from 'hooks/useParticipant';
import { useRaffle } from 'hooks/useRaffle';
import { useState } from 'react';
import MainCard from 'ui-component/cards/MainCard';
import { AddParticipantDialog } from './AddParticipantDialog';
import { Payment } from './Payment';

export const Participants = () => {
    const { participants, loadParticipants, syncParticipant } = useParticipant();
    const { externalRef, onChangeForm } = useForm({
        externalRef: ''
    });
    const [open, setOpen] = useState(false);
    const { raffles } = useRaffle();
    const onSubmit = async (event) => {
        event.preventDefault();
        console.log('buscando', externalRef);
        await loadParticipants(externalRef);
    };

    const actions = [{ label: 'Crear', icon: 'teast', onClick: () => setOpen(true) }];

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <MainCard title="Participantes" actions={actions}>
                <form onSubmit={onSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-select-small">MP References</InputLabel>
                                <Select
                                    labelId="demo-select-small"
                                    id="demo-select-small"
                                    name="externalRef"
                                    value={externalRef}
                                    label="MP References"
                                    onChange={onChangeForm}
                                >
                                    <MenuItem value="">None</MenuItem>
                                    {raffles.map((raffle) => (
                                        <MenuItem key={raffle.mp_reference} value={raffle.mp_reference}>
                                            {raffle.mp_reference}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item>
                            <Tooltip title="Buscar">
                                <IconButton type="submit" aria-label="SavePeople">
                                    <Search />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </form>
                {participants.length ? (
                    <TableContainer component={Paper}>
                        {/* mejorar esto en otro componente */}
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Payment ID</TableCell>
                                    <TableCell align="left">Nombre</TableCell>
                                    <TableCell align="right">Email</TableCell>
                                    <TableCell align="right">Facturado</TableCell>
                                    <TableCell align="right">Comunicado</TableCell>
                                    <TableCell align="center">Acciones</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {participants.map((row) => (
                                    <Payment key={row.mp_payment_id} row={row} />
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                ) : (
                    <></>
                )}
            </MainCard>
            <AddParticipantDialog open={open} setOpen={setOpen} saveParticipant={syncParticipant} />
        </LocalizationProvider>
    );
};
