import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useParticipant } from 'hooks/useParticipant';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';

import './DragAndDrop.css';
import Dropzone from 'react-dropzone';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2)
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1)
    }
}));

function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500]
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired
};

export const UploadBillDialog = ({ open, setOpen, sendBill, setSelectedFile, selectedFile, row }) => {
    const { raffles } = useParticipant();

    const handleClose = () => {
        setOpen(false);
    };

    const updateFile = (files) => {
        setSelectedFile(files[0]);
    };

    const saveAndClose = (event) => {
        event.preventDefault();
        sendBill();
        handleClose();
    };

    return (
        <form onSubmit={saveAndClose}>
            <BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                    Cargar Factura Para el Participante {row.name || row.paymentId}
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    <Dropzone onDrop={(acceptedFiles) => updateFile(acceptedFiles)}>
                        {({ getRootProps, getInputProps }) => (
                            <section>
                                <div {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    {selectedFile ? <p>{selectedFile.name}</p> : <p>Drag drop some files here, or click to select files</p>}
                                </div>
                            </section>
                        )}
                    </Dropzone>
                </DialogContent>
                <DialogActions>
                    <Button type="submit" onClick={saveAndClose}>
                        Guardar
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </form>
    );
};
