import React, { useCallback } from 'react';
import { useMemo } from 'react';
import { useState } from 'react';
import { useDropzone } from 'react-dropzone';

const DragAndDrop = ({ onDrop }) => {
    const [preview, setPreview] = useState(null);

    const handleDrop = useCallback(
        (acceptedFiles) => {
            if (acceptedFiles.length > 0) {
                const file = acceptedFiles[0];
                const reader = new FileReader();

                reader.onload = () => {
                    // Obtener la información del archivo
                    const fileData = {
                        name: file.name,
                        type: file.type,
                        size: file.size,
                        dataURL: reader.result
                    };
                    setPreview(fileData);
                    onDrop(file); // Llamar a la función onDrop con los datos del archivo
                };

                reader.readAsDataURL(file);
            }
        },
        [onDrop]
    );

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop: handleDrop });

    const style = useMemo(() => ({
        ...baseStyle
    }));

    return (
        <div {...getRootProps({ style })} className={`dropzone ${isDragActive ? 'active' : ''}`}>
            <input {...getInputProps()} />
            {isDragActive ? <p>Suelta el archivo aquí...</p> : <p>Haz click para seleccionar un archivo.</p>}
            {preview ? <img src={preview.dataURL} alt="" style={{ display: 'block', width: 'auto', height: 200 }} /> : <></>}
        </div>
    );
};

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
};

export default DragAndDrop;
