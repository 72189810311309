import { Box, Button } from '@mui/material';
import { useRaffle } from 'hooks/useRaffle';
import React, { useEffect, useRef } from 'react';
import { useState } from 'react';
import { Randomnize } from 'ui-component/slot/Randomnize';
import { Slot } from 'ui-component/slot/Slot';

export const Game = ({ raffle }) => {
    const [wheel, setWheel] = useState(false);
    const [finished, setFinished] = useState(false);
    const [winner, setWinner] = useState(null);
    const [award, setAward] = useState(null);
    const [awards, setAwards] = useState([]);
    const [participants, setParticipants] = useState([]);
    const { updateRaffleAwards } = useRaffle();
    const mixer = useRef();

    useEffect(() => {
        parseAwards();
        parseParticipants();
    }, []);

    /**
     * on finish wheel
     */
    useEffect(() => {
        if (finished) {
            setAwards((values) => {
                const index = values.indexOf(award);
                values[index].winner = winner;
                values[index].enabled = false;
                return values;
            });
            update();
        }
    }, [finished]);

    const parseAwards = () => {
        setAwards((array) => {
            const aux = [];
            raffle.awards.forEach((item) => {
                const value = {
                    _id: item._id,
                    order: item.order,
                    title: item.title,
                    winner: item.participant ? item.participant : null,
                    enabled: item.participant ? false : true
                };
                aux.push(value);
            });
            return aux;
        });
    };

    const parseParticipants = () => {
        setParticipants(() => {
            const aux = [];
            raffle.participants.forEach((value, index) => {
                for (let i = 0; i < value.tickets; i++) {
                    value.color = `linear-gradient(135deg, #e0c3fc 0%, #8ec5fc 100%)`;
                    value.winner = false;
                    aux.push(value);
                }
            });
            return aux;
        });
    };

    const update = () => {
        setParticipants((values) => {
            values.forEach((item) => {
                if (item._id === winner._id) {
                    item.winner = true;
                }
            });
            return values;
        });
    };

    const isRaffleFinished = () => {
        let flag = true;
        awards.forEach((v) => {
            if (v.enabled) {
                flag = false;
            }
        });
        return flag;
    };

    const finishRaffle = () => {
        raffle.awards.forEach((v) => {
            let res = awards.find((award) => v._id === award._id);
            if (res) {
                v.participant = res.winner;
            }
        });

        raffle.awards.forEach((award) => {
            updateRaffleAwards(award.order, { _id: award.participant._id });
        });
    };

    /**
     * handle randomnize component mixerItmes function
     */
    const handleRandomnize = () => {
        mixer.current();
    };

    const handleAward = (award) => {
        setAward(award);
    };

    const reset = () => {
        setWheel(false);
        setAward(null);
        setWinner(null);
        setFinished(false);
    };

    const showAwards = () => {
        return (
            <Box>
                <div style={{ ...baseStyle.center, margin: 10 }}>Select award to get started</div>
                {awards.map((item, index) => (
                    <div key={index} style={{ ...baseStyle.center, margin: 20 }}>
                        {/* eslint-disable-next-line */}
                        <div
                            style={{ ...baseStyle.box, ...baseStyle.center, cursor: 'pointer', pointerEvents: item.enabled ? '' : 'none' }}
                            onClick={() => handleAward(item)}
                        >
                            <div>
                                <div style={{ margin: 10 }}>
                                    {item.order} °{'  -  '}
                                    {item.title}
                                </div>
                                <span style={baseStyle.center}>{item.winner ? 'Ganador:  @' + item.winner.userName : ''}</span>
                                {item.participant ? <span style={baseStyle.center}>{item.participant.userName}</span> : <></>}
                            </div>
                        </div>
                    </div>
                ))}
                {isRaffleFinished() && raffle.enabled ? (
                    <div style={{ ...baseStyle.center }}>
                        <Button variant="contained" style={{ marginRight: 10 }} onClick={() => finishRaffle()}>
                            Finish Raffle
                        </Button>
                    </div>
                ) : (
                    <></>
                )}
            </Box>
        );
    };

    const setActions = () => {
        return (
            <>
                <div style={{ ...baseStyle.center, fontSize: 18, margin: 30, font: 'bold' }}>
                    {award ? award.order + '° - ' + award.title : ''}
                </div>

                {!finished ? (
                    <div style={{ ...baseStyle.center }}>
                        {!wheel ? (
                            <Button variant="contained" style={{ marginRight: 10 }} onClick={() => setAward(null)}>
                                Back to awards
                            </Button>
                        ) : (
                            <></>
                        )}

                        {!wheel ? (
                            <Button variant="contained" onClick={() => handleRandomnize()}>
                                Start Mixer
                            </Button>
                        ) : (
                            <></>
                        )}

                        <Button variant="contained" style={{ marginLeft: 10 }} onClick={() => setWheel((val) => !val)}>
                            {wheel ? 'Back to mixer' : 'wheel'}
                        </Button>
                    </div>
                ) : (
                    <div style={{ ...baseStyle.center }}>
                        <Button variant="contained" style={{ marginLeft: 10 }} onClick={() => reset()}>
                            Back to Awards
                        </Button>
                    </div>
                )}
            </>
        );
    };

    const setMachine = () => {
        return (
            <div>
                {wheel ? (
                    <Slot data={participants} setFinished={setFinished} setWinner={setWinner} />
                ) : (
                    <Randomnize data={participants} setData={setParticipants} mixer={mixer} />
                )}
            </div>
        );
    };

    const showWinner = () => {
        return (
            <Box>
                <div style={{ ...baseStyle.center, margin: 40 }}>
                    <div style={{ ...baseStyle.box, ...baseStyle.center }}>
                        <div>
                            <div style={{ ...baseStyle.center, fontSize: 16, margin: 10 }}>Ganador</div>
                            <span style={baseStyle.center}>@{winner.userName}</span>
                        </div>
                    </div>
                </div>
            </Box>
        );
    };

    return raffle && raffle.participants.length > 0 ? (
        <>
            {!award ? showAwards() : <></>}
            {award ? setActions() : <></>}
            {award && !finished ? setMachine() : <></>}
            {winner ? showWinner() : <></>}
        </>
    ) : (
        <div style={{ ...baseStyle.center, margin: 20 }}>Participants is empty.</div>
    );
};

const baseStyle = {
    center: {
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex'
    },
    box: {
        width: 500,
        height: 100,
        background: 'linear-gradient(135deg, #e0c3fc 0%, #8ec5fc 100%)',
        borderRadius: 5,
        color: 'white'
    }
};
