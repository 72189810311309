import { api } from '../axios';

const getMovements = async () => {
    try {
        const response = await api.get(`/all-movements`);
        return response.data;
    } catch (error) {
        console.error('Error fetching movements:', error);
        throw error;
    }
};

export const uploadBillApi = (movementId, billFile) => {
    const formData = new FormData();
    formData.append('billFile', billFile);
    return api.post(`/movements/make-bill`, formData, {
        params: {
            movementId
        },
        headers: { 'Content-Type': 'multipart/form-data' }
    });
};

export const sendMailApi = (movementId) => {
    return api.get(`/movements/send-mail`, { params: { movementId } });
};

const MovementService = {
    getMovements
};

export default MovementService;
