import { api } from '../axios';

export const signInService = (credentials) => {
    return api.post(`/signin`, credentials);
};

export const setTokenStorageService = (token) => {
    localStorage.setItem('auth_token', token);
};

export const getTokenStorageService = () => {
    return localStorage.getItem('auth_token');
};

export const clearTokenStorage = () => {
    localStorage.clear('auth_token');
};
