import React from 'react';
import PropTypes from 'prop-types';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography
} from '@mui/material';

const MovementDetailDialog = ({ open, onClose, movement }) => {
    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
            <DialogTitle>Movement Details</DialogTitle>
            <DialogContent>
                {movement && (
                    <>
                        <Typography variant="h6">Client: {movement.clientId.firstName}</Typography>
                        <Typography variant="body1">Tickets: {movement.tickets}</Typography>
                        <Typography variant="body1">Total Amount: ${movement.totalAmount}</Typography>
                        <Typography variant="body1">Date: {new Date(movement.date).toLocaleDateString()}</Typography>
                        <TableContainer component={Paper} style={{ marginTop: 20 }}>
                            <Table sx={{ minWidth: 650 }} aria-label="product table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center">Image</TableCell>
                                        <TableCell>Name</TableCell>
                                        <TableCell align="right">Quantity</TableCell>
                                        <TableCell align="right">Tickets</TableCell>
                                        <TableCell align="right">Price</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {movement.products.map((productItem) => (
                                        <TableRow
                                            key={productItem.productId._id}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell align="center">
                                                <img src={productItem.productId.picture} alt="img" height={50} />
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {productItem.productId.name}
                                            </TableCell>
                                            <TableCell align="right">{productItem.quantity}</TableCell>
                                            <TableCell align="right">{productItem.tickets}</TableCell>
                                            <TableCell align="right">${productItem.price}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Close</Button>
            </DialogActions>
        </Dialog>
    );
};

MovementDetailDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    movement: PropTypes.object
};

export default MovementDetailDialog;
