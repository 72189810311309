import React from 'react';
import Button from '@mui/material/Button';

const FileUploadButton = ({ onFileSelect }) => {
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            onFileSelect(file); // Pasamos el archivo seleccionado al evento onFileSelect
        }
    };

    return (
        <div style={{ textAlign: 'center' }}>
            <input accept="image/*" style={{ display: 'none' }} id="file-input" type="file" onChange={handleFileChange} />
            <label htmlFor="file-input">
                <Button variant="contained" component="span" color="primary">
                    Cambiar Imagen
                </Button>
            </label>
        </div>
    );
};

export default FileUploadButton;
