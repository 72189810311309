import { useState } from 'react';
import ProductService from 'services/Product';

const useProduct = () => {
    const [products, setProducts] = useState([]);
    const [product, setProduct] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const fetchProducts = async () => {
        setLoading(true);
        try {
            const data = await ProductService.getProducts();
            setProducts(data);
        } catch (err) {
            setError(err);
        } finally {
            setLoading(false);
        }
    };

    const fetchProduct = async (productId) => {
        setLoading(true);
        try {
            const data = await ProductService.getProduct(productId);
            setProduct(data);
        } catch (err) {
            setError(err);
        } finally {
            setLoading(false);
        }
    };

    const createProduct = async (productData) => {
        setLoading(true);
        try {
            const data = await ProductService.saveProduct(productData);
            setProducts((prevProducts) => [...prevProducts, data]);
            return data;
        } catch (err) {
            setError(err);
        } finally {
            setLoading(false);
        }
    };

    const editProduct = async (productId, productData) => {
        setLoading(true);
        try {
            const data = await ProductService.updateProduct(productId, productData);
            setProducts((prevProducts) => prevProducts.map((product) => (product.id === productId ? data : product)));
        } catch (err) {
            setError(err);
        } finally {
            setLoading(false);
        }
    };

    const editProductImage = async (productId, file) => {
        setLoading(true);
        try {
            const data = await ProductService.updateImageProduct(productId, file);
            setProducts((prevProducts) =>
                prevProducts.map((product) => (product.id === productId ? { ...product, image: data.image } : product))
            );
        } catch (err) {
            setError(err);
        } finally {
            setLoading(false);
        }
    };

    const removeProduct = async (productId) => {
        setLoading(true);
        try {
            await ProductService.deleteProduct(productId);
            setProducts((prevProducts) => prevProducts.filter((product) => product.id !== productId));
        } catch (err) {
            setError(err);
        } finally {
            setLoading(false);
        }
    };

    const uploadDigitalFile = async (productId, file) => {
        setLoading(true);
        try {
            const data = await ProductService.updateDigitalFile(productId, file);
            setProducts((prevProducts) =>
                prevProducts.map((product) => (product.id === productId ? { ...product, digitalFile: data.digitalFile } : product))
            );
        } catch (err) {
            setError(err);
        } finally {
            setLoading(false);
        }
    };

    return {
        products,
        product,
        loading,
        error,
        fetchProducts,
        fetchProduct,
        createProduct,
        editProduct,
        editProductImage,
        removeProduct,
        uploadDigitalFile
    };
};

export default useProduct;
