import React from 'react';
import { useSpring, animated } from '@react-spring/web';
import * as easings from 'd3-ease';
import { useEffect } from 'react';
import { useState } from 'react';
import './SlotStyle.css';
import { Button } from '@mui/material';

const BOX_WIDTH = 150;
const BOX_HEIGHT = 150;
const BOX_MARGIN = 3;
const LAUNCH_ROW = 15;

export const Slot = ({ data, setFinished, setWinner }) => {
    /* const items = [
        { id: 1, color: '#FFE780', userName: '@adminsuerteo' },
        { id: 2, color: '#87CCE8', userName: '@johonDoe' },
        { id: 3, color: '#FFA3A1', userName: '@Markcuabn' },
        { id: 4, color: '#B1DFD0', userName: '@Tosckisvock' },
        { id: 5, color: 'red', userName: '@LaRata' }
    ]; */

    const [wheel, setWheel] = useState([{ row: data }]);
    const [point, setPoint] = useState(0);
    const [rows] = useState(data.length * 50);
    const [start, setStart] = useState(false);

    const springs = useSpring({
        from: { x: point },
        to: { x: 0 },
        config: {
            duration: 10000,
            easing: easings.easeCubicOut
        },
        reverse: true
    });

    useEffect(() => {
        setWinner(null);
        fillWheel();
    }, []);

    const fillWheel = () => {
        for (let i = 0; i < rows - 1; i++) {
            setWheel((wheel) => [...wheel, { row: data }]);
        }
    };

    const handleWheel = () => {
        setStart(true);
        let max = data.length;
        let random = Math.floor(Math.random() * (max - 0) + 0);
        let box = BOX_WIDTH + BOX_MARGIN * 2;
        let rowSize = box * data.length;
        let startInRow = rowSize * LAUNCH_ROW;
        let boxWinner = box * random + Math.floor(Math.random() * (BOX_WIDTH - 5) + 5);
        let landing = -(startInRow + boxWinner);

        setPoint(landing);
        setTimeout(() => {
            setStart(false);
            setWinner(data[random]);
            setFinished(true);
        }, 12000);
    };

    const getRow = (row) => {
        return (
            <div style={baseStyle.row}>
                {row.map((item, index) => (
                    <div key={index} style={{ ...baseStyle.box, background: 'linear-gradient(135deg, #e0c3fc 0%, #8ec5fc 100%)' }}>
                        <div style={{ color: 'white' }}>
                            <span>@{item.userName}</span>
                        </div>
                    </div>
                ))}
            </div>
        );
    };

    return (
        <>
            <div style={baseStyle.main}>
                <div style={baseStyle.selector}>
                    <div className="triangle-down"></div>
                </div>
                <div style={baseStyle.constainer}>
                    <animated.div style={{ ...baseStyle.row, ...springs }}>
                        {wheel ? wheel.map((item, index) => <div key={index}>{getRow(item.row)}</div>) : <div>sin datos</div>}
                    </animated.div>
                </div>
                <div style={baseStyle.selector}>
                    <div className="triangle-up"></div>
                </div>

                <div style={{ ...baseStyle.selector, marginTop: 60 }}>
                    <Button variant="contained" onClick={() => handleWheel()} disabled={start}>
                        Start
                    </Button>
                </div>
            </div>
        </>
    );
};

const baseStyle = {
    main: {
        paddingLeft: 200,
        paddingRight: 200,
        paddingTop: 70
    },
    constainer: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        height: '100%',
        width: '100%',
        display: 'flex',
        overflow: 'hidden'
    },
    row: { display: 'flex' },
    box: {
        height: BOX_HEIGHT,
        width: BOX_WIDTH,
        borderRadius: 8,
        margin: BOX_MARGIN,
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        boxShadow: '0px 2px 6px rgba(0,0,0,0.20)'
    },
    selector: {
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex'
    }
};
