import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, IconButton, Paper } from '@mui/material';
import { Description, Mail, Visibility } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import useMovement from 'hooks/useMovements';
import MainCard from 'ui-component/cards/MainCard';
import MovementDetailDialog from 'ui-component/MovementDetailDialog';
import { UploadBillDialog } from 'views/participants/UploadBillDialog';

const Movements = () => {
    const { movements, fetchMovements, uploadMovementBill, sendMovementMail } = useMovement();
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedMovement, setSelectedMovement] = useState(null);
    const [openUpload, setOpenUpload] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const navigate = useNavigate();

    const sendBill = async () => {
        await uploadMovementBill(selectedMovement._id, selectedFile);
        setSelectedMovement(null);
    };

    useEffect(() => {
        fetchMovements();
    }, []);

    useEffect(() => {
        if (openUpload) {
        }
    }, [openUpload]);

    const handleOpenDialog = (movement) => {
        setSelectedMovement(movement);
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setSelectedMovement(null);
    };

    const handleGenerateInvoice = (movement) => {
        setSelectedMovement(movement);
        setOpenUpload(true);
    };

    const handleSendMail = (movementId) => {
        sendMovementMail(movementId);
    };

    return (
        <MainCard title="Movements">
            {selectedMovement && (
                <UploadBillDialog
                    open={openUpload}
                    setOpen={setOpenUpload}
                    setSelectedFile={setSelectedFile}
                    selectedFile={selectedFile}
                    sendBill={sendBill}
                    row={selectedMovement}
                />
            )}
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Tickets</TableCell>
                            <TableCell>Client Doc.</TableCell>
                            <TableCell align="right">Total Amount</TableCell>
                            <TableCell align="right">Date</TableCell>
                            <TableCell align="center">Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {movements.map((movement) => (
                            <TableRow key={movement._id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell component="th" scope="row">
                                    {movement.tickets}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {movement.clientDocument}
                                </TableCell>
                                <TableCell align="right">${movement.totalAmount}</TableCell>
                                <TableCell align="right">{new Date(movement.date).toLocaleDateString()}</TableCell>
                                <TableCell align="center">
                                    <Tooltip title="View Details">
                                        <IconButton onClick={() => handleOpenDialog(movement)} aria-label="View Details" color="primary">
                                            <Visibility />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Generate Invoice">
                                        <IconButton
                                            onClick={() => handleGenerateInvoice(movement)}
                                            aria-label="Generate Invoice"
                                            color="primary"
                                            disabled={movement.isBilled}
                                        >
                                            <Description />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Send Mail">
                                        <IconButton
                                            onClick={() => handleSendMail(movement._id)}
                                            aria-label="Send Mail"
                                            color="primary"
                                            disabled={movement.isMailSended || !movement.isBilled}
                                        >
                                            <Mail />
                                        </IconButton>
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <MovementDetailDialog open={openDialog} onClose={handleCloseDialog} movement={selectedMovement} />
        </MainCard>
    );
};

export default Movements;
