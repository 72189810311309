import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useForm } from 'hooks/useForm';
import { Grid, TextField } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { useState } from 'react';
import DragAndDrop from 'ui-component/DragAndDrop';
import { useEffect } from 'react';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2)
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1)
    }
}));

function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500]
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired
};

export const CustomDialog = ({ open, setOpen, saveRaffle, award }) => {
    const emptyAward = {
        title: '',
        order: 0,
        minimumParticipants: 0,
        description: ''
    };
    const { title, order, description, minimumParticipants, form, onChangeForm, setForm } = useForm(emptyAward);

    const [awardImage, setAwardImage] = useState();

    const handleClose = () => {
        setOpen(false);
    };

    const saveAndClose = (event) => {
        event.preventDefault();
        handleClose();
        saveRaffle(form, awardImage);
    };

    const handleFileDrop = async (fileData) => {
        // Aquí puedes enviar los datos del archivo a través de Axios
        try {
            setAwardImage(fileData);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        setForm(award || emptyAward);
    }, [award]);

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <form onSubmit={saveAndClose}>
                <BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
                    <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                        New Award
                    </BootstrapDialogTitle>
                    <DialogContent dividers>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    id="title"
                                    label="Title"
                                    variant="outlined"
                                    name="title"
                                    fullWidth
                                    value={title}
                                    onChange={onChangeForm}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField id="order" label="Order" variant="outlined" name="order" value={order} onChange={onChangeForm} />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    id="minimumParticipants"
                                    label="Minimum Participants"
                                    variant="outlined"
                                    name="minimumParticipants"
                                    value={minimumParticipants}
                                    onChange={onChangeForm}
                                />
                            </Grid>
                        </Grid>
                        <Grid style={{ marginTop: 15, marginBottom: 15 }}>
                            <TextField
                                id="description"
                                label="Description"
                                variant="outlined"
                                name="description"
                                multiline
                                rows={4}
                                fullWidth
                                value={description}
                                onChange={onChangeForm}
                            />
                        </Grid>
                        <Grid style={{ marginTop: 15, marginBottom: 15 }}>
                            <DragAndDrop onDrop={handleFileDrop} />
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button type="submit" onClick={saveAndClose}>
                            Save changes
                        </Button>
                    </DialogActions>
                </BootstrapDialog>
            </form>
        </LocalizationProvider>
    );
};
