import { useState, useEffect } from 'react';
import MovementService, { sendMailApi, uploadBillApi } from 'services/Movements';

const useMovement = () => {
    const [movements, setMovements] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const fetchMovements = async () => {
        setLoading(true);
        try {
            const data = await MovementService.getMovements();
            setMovements(data);
        } catch (err) {
            setError(err);
        } finally {
            setLoading(false);
        }
    };

    const uploadMovementBill = async (movementId, billFile) => {
        setLoading(true);
        await uploadBillApi(movementId, billFile);
        fetchMovements();
        setLoading(false);
    };

    const sendMovementMail = async (movementId) => {
        setLoading(true);
        await sendMailApi(movementId);
        setParticipant({ ...participant, isMailSended: true });
        fetchMovements();
        setLoading(false);
    };

    useEffect(() => {
        fetchMovements();
    }, []);

    return {
        movements,
        loading,
        error,
        fetchMovements,
        uploadMovementBill,
        sendMovementMail
    };
};

export default useMovement;
