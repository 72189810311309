import React, { useEffect, useState } from 'react';
import { Button, Container, Grid, TextField } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useForm } from 'hooks/useForm';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useProduct from 'hooks/useProduct';
import MainCard from 'ui-component/cards/MainCard';
import DragAndDrop from 'ui-component/DragAndDrop';

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
};

export const ProductDetail = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { product, fetchProduct, editProduct, uploadDigitalFile } = useProduct();
    const [digitalFile, setDigitalFile] = useState(null);

    const { name, price, description, tickets, discount, category, picture, onChangeForm, setForm } = useForm(
        product || {
            name: '',
            price: 0,
            tickets: 0,
            discount: 0,
            category: '',
            description: '',
            picture: ''
        }
    );

    useEffect(() => {
        fetchProduct(searchParams.get('id'));
    }, [searchParams]);

    useEffect(() => {
        if (product) {
            setForm(product);
        }
    }, [product, setForm]);

    const handleUpdate = () => {
        editProduct(searchParams.get('id'), {
            name,
            price,
            description,
            tickets,
            discount,
            category,
            picture
        });
        navigate(`/products`);
    };
    const handleUploadDigitalFile = async () => {
        if (digitalFile) {
            await uploadDigitalFile(searchParams.get('id'), digitalFile);
            navigate(`/products`);
        }
    };

    return (
        <MainCard>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Container fixed>
                    <Grid item style={{ marginTop: 15, marginBottom: 15 }}>
                        <TextField id="name" label="Name" variant="outlined" name="name" fullWidth value={name} onChange={onChangeForm} />
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <TextField
                                id="price"
                                label="Price"
                                variant="outlined"
                                name="price"
                                fullWidth
                                value={price}
                                onChange={onChangeForm}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                id="tickets"
                                label="Tickets"
                                variant="outlined"
                                name="tickets"
                                fullWidth
                                value={tickets}
                                onChange={onChangeForm}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                id="discount"
                                label="Discount"
                                variant="outlined"
                                name="discount"
                                fullWidth
                                value={discount}
                                onChange={onChangeForm}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                id="category"
                                label="Category"
                                variant="outlined"
                                name="category"
                                fullWidth
                                value={category}
                                onChange={onChangeForm}
                            />
                        </Grid>
                    </Grid>
                    <Grid item style={{ marginTop: 15, marginBottom: 15 }}>
                        <TextField
                            id="description"
                            label="Description"
                            variant="outlined"
                            name="description"
                            fullWidth
                            multiline
                            rows={3}
                            value={description}
                            onChange={onChangeForm}
                        />
                    </Grid>
                    <Grid item style={{ marginTop: 15, marginBottom: 15 }}>
                        <DragAndDrop onDrop={setDigitalFile} />
                    </Grid>
                    <Grid
                        item
                        xs={4}
                        container
                        justifyContent="space-between"
                        style={{ marginTop: '20px', marginBottom: '25px', width: '100%' }}
                    >
                        <Button variant="contained" onClick={handleUploadDigitalFile}>
                            Upload Digital File
                        </Button>
                    </Grid>
                    <Grid
                        item
                        xs={4}
                        container
                        justifyContent="space-between"
                        style={{ marginTop: '20px', marginBottom: '25px', width: '100%' }}
                    >
                        <Button variant="contained" onClick={handleUpdate}>
                            Save
                        </Button>
                    </Grid>
                    <Grid item xs={4} style={baseStyle}>
                        <span style={{ marginBottom: 20 }}>Image</span>
                        {picture && <img src={picture} alt="Product" height={300} />}
                    </Grid>
                </Container>
            </LocalizationProvider>
        </MainCard>
    );
};

export default ProductDetail;
