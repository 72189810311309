import { Search } from '@mui/icons-material';
import {
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Tooltip
} from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useForm } from 'hooks/useForm';
import { useMP } from 'hooks/useMP';
import { useRaffle } from 'hooks/useRaffle';
import MainCard from 'ui-component/cards/MainCard';
import { Payment } from './Payment';

export const MPPayments = () => {
    const { payments, getPayments } = useMP();
    const { externalRef, payment_date, onChangeForm } = useForm({
        externalRef: '',
        payment_date: null
    });
    const { raffles } = useRaffle();
    const onSubmit = async (event) => {
        event.preventDefault();
        console.log('buscando', externalRef);
        await getPayments(externalRef);
    };
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <MainCard title="MP Pagos">
                <form onSubmit={onSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-select-small">MP References</InputLabel>
                                <Select
                                    labelId="demo-select-small"
                                    id="demo-select-small"
                                    name="externalRef"
                                    value={externalRef}
                                    label="MP References"
                                    onChange={onChangeForm}
                                >
                                    <MenuItem value="">None</MenuItem>
                                    {raffles.map((raffle) => (
                                        <MenuItem key={raffle.mp_reference} value={raffle.mp_reference}>
                                            {raffle.mp_reference}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <DesktopDatePicker
                                name="payment_date"
                                label="End Date"
                                inputFormat="DD/MM/YYYY"
                                value={payment_date}
                                onChange={(e) => onChangeForm({ target: { value: e, name: 'payment_date' } })}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </Grid>
                        <Grid item>
                            <Tooltip title="Buscar">
                                <IconButton type="submit" aria-label="SavePeople">
                                    <Search />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </form>
                {payments.length ? (
                    <TableContainer component={Paper}>
                        {/* mejorar esto en otro componente */}
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Payment ID</TableCell>
                                    <TableCell align="right">Email</TableCell>
                                    <TableCell align="center">Acciones</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {payments.map((row) => (
                                    <Payment key={row.mp_payment_id} row={row} />
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                ) : (
                    <></>
                )}
            </MainCard>
        </LocalizationProvider>
    );
};
