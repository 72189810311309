import { useState } from 'react';

export const useForm = (initForm = {}) => {
    const [form, setForm] = useState(initForm);

    const onChangeForm = ({ target }) => {
        const { name, value } = target;
        setForm({
            ...form,
            [name]: value
        });
    };

    const onCleanForm = () => {
        setForm(initForm);
    };

    return {
        ...form,
        form,
        onChangeForm,
        onCleanForm,
        setForm
    };
};
