import { useEffect } from 'react';
import { useState } from 'react';
import {
    getParticipant,
    getParticipants,
    sendMailApi,
    syncParticipantApi,
    updateParticipantApi,
    uploadBillApi
} from 'services/Participant';

export const useParticipant = (paymentId) => {
    const [isLoading, setIsLoading] = useState(false);
    const [participant, setParticipant] = useState(false);
    const [participants, setParticipants] = useState([]);

    useEffect(() => {
        if (paymentId) {
            setIsLoading(true);
            getParticipant(paymentId)
                .then((participant) => {
                    setIsLoading(false);
                    setParticipant(participant);
                })
                .catch(() => setIsLoading(false));
        }
    }, []);

    const syncParticipant = async (row) => {
        setIsLoading(true);
        const participant = await syncParticipantApi(row);
        setIsLoading(false);
        setParticipant(participant);
    };

    const updateParticipant = async (row) => {
        setIsLoading(true);
        await updateParticipantApi(row);
        setIsLoading(false);
    };

    const loadParticipants = async (mp_reference) => {
        setIsLoading(true);
        const response = await getParticipants(mp_reference);
        const participants = response.data;
        setIsLoading(false);
        setParticipants(participants);
    };

    const uploadBill = async (mp_payment_id, billFile) => {
        setIsLoading(true);
        await uploadBillApi(mp_payment_id, billFile);
        setParticipant({ ...participant, isBilled: true });
        setIsLoading(false);
    };

    const sendMail = async (mp_payment_id) => {
        setIsLoading(true);
        await sendMailApi(mp_payment_id);
        setParticipant({ ...participant, isMailSended: true });
        setIsLoading(false);
    };

    return {
        isLoading,
        participant,
        syncParticipant,
        participants,
        loadParticipants,
        updateParticipant,
        uploadBill,
        sendMail
    };
};
