// material-ui
import { IconButton, Paper, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import { AddBox } from '@mui/icons-material';
import useProduct from 'hooks/useProduct';
import { NewProductDialog } from 'ui-component/NewProductDialog';

const Products = () => {
    const { products, fetchProducts, createProduct, editProduct, editProductImage } = useProduct();
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();

    const actions = [{ label: 'Create', icon: 'test', onClick: () => setOpen(true) }];

    const handleUpdateSwitchValue = (item, attribute, value) => {
        const updatedItem = {
            _id: item._id
        };
        updatedItem[attribute] = value;
        editProduct(item._id, updatedItem);
    };

    useEffect(() => {
        fetchProducts();
    }, []);

    return (
        <MainCard title="Products" actions={actions}>
            <NewProductDialog
                open={open}
                setOpen={setOpen}
                saveProduct={createProduct}
                uploadImageProduct={editProductImage}
                fetchProducts={fetchProducts}
            />
            {products ? (
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell align="right">Price</TableCell>
                                <TableCell align="center">Image</TableCell>
                                <TableCell align="right">Tickets</TableCell>
                                <TableCell align="right">Discount</TableCell>
                                <TableCell align="right">Category</TableCell>
                                <TableCell align="right">Public</TableCell>
                                <TableCell align="right">Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {products.map((row) => (
                                <TableRow key={row.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell component="th" scope="row">
                                        {row.name}
                                    </TableCell>
                                    <TableCell align="right">${row.price}</TableCell>
                                    <TableCell align="center">
                                        <img src={row.picture} alt="img" height={50} />
                                    </TableCell>
                                    <TableCell align="right">{row.tickets}</TableCell>
                                    <TableCell align="right">{row.discount}</TableCell>
                                    <TableCell align="right">{row.category}</TableCell>
                                    <TableCell align="right">
                                        <Switch
                                            color="primary"
                                            checked={row.isPublic}
                                            onChange={(e) => handleUpdateSwitchValue(row, 'isPublic', e.target.checked)}
                                            name="sdm"
                                        />
                                    </TableCell>
                                    <TableCell align="right">
                                        <Tooltip title="View Details">
                                            <IconButton
                                                aria-label="ViewDetails"
                                                color="primary"
                                                onClick={() => navigate(`/product?id=${row._id}`)}
                                            >
                                                <AddBox />
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            ) : (
                <></>
            )}
        </MainCard>
    );
};

export default Products;
