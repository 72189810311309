import { api } from '../axios';

const getProducts = async () => {
    try {
        const response = await api.get(`/products`);
        return response.data;
    } catch (error) {
        console.error('Error fetching products:', error);
        throw error;
    }
};

const getProduct = async (productId) => {
    try {
        const response = await api.get(`/product/${productId}`);
        return response.data;
    } catch (error) {
        console.error(`Error fetching product with id ${productId}:`, error);
        throw error;
    }
};

const saveProduct = async (productData) => {
    try {
        const response = await api.post(`/product`, productData);
        return response.data;
    } catch (error) {
        console.error('Error saving product:', error);
        throw error;
    }
};

const updateProduct = async (productId, productData) => {
    try {
        const response = await api.put(`/product/${productId}`, productData);
        return response.data;
    } catch (error) {
        console.error(`Error updating product with id ${productId}:`, error);
        throw error;
    }
};

const updateImageProduct = async (productId, file) => {
    try {
        const formData = new FormData();
        formData.append('archivo', file);
        const response = await api.put(`/product/${productId}/image`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return response.data;
    } catch (error) {
        console.error(`Error updating image for product with id ${productId}:`, error);
        throw error;
    }
};

const deleteProduct = async (productId) => {
    try {
        const response = await api.delete(`/product/${productId}`);
        return response.data;
    } catch (error) {
        console.error(`Error deleting product with id ${productId}:`, error);
        throw error;
    }
};

const updateDigitalFile = async (productId, file) => {
    try {
        const formData = new FormData();
        formData.append('digitalFile', file);
        const response = await api.post(`/product/${productId}/digital-file`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return response.data;
    } catch (error) {
        console.error(`Error updating digital file for product with id ${productId}:`, error);
        throw error;
    }
};

const ProductService = {
    getProducts,
    getProduct,
    saveProduct,
    updateProduct,
    updateImageProduct,
    deleteProduct,
    updateDigitalFile
};

export default ProductService;
