import React, { useState } from 'react';
import Banner from './Banner';
import FileUploadButton from './FileUploadButton';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

const ImageUploaderForm = ({ initialImage, onFileChange }) => {
    const [imageSrc, setImageSrc] = useState(initialImage);
    const [currentFile, setCurrentFile] = useState(null);

    const handleFileSelect = (file) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            setImageSrc(reader.result);
            setCurrentFile(file);
            onFileChange(file); // Llama a la función de callback para devolver el archivo
        };
        reader.readAsDataURL(file);
    };

    const handleCancel = () => {
        setImageSrc(initialImage);
        setCurrentFile(null);
        onFileChange(null); // Devuelve null al cancelar
    };

    return (
        <Box>
            <Banner imageSrc={imageSrc} />
            <Box sx={{ display: 'flex', marginBottom: '1.5rem', justifyContent: 'end' }}>
                <FileUploadButton onFileSelect={handleFileSelect} />
                {currentFile && (
                    <Button variant="text" color="secondary" onClick={handleCancel} style={{ marginLeft: '10px' }}>
                        Cancelar
                    </Button>
                )}
            </Box>
        </Box>
    );
};

export default ImageUploaderForm;
