import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Tooltip } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useState } from 'react';
import DragAndDrop from 'ui-component/DragAndDrop';
import { Image } from '@mui/icons-material';
import { useRaffle } from 'hooks/useRaffle';
import { LocalizationProvider } from '@mui/x-date-pickers';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2)
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1)
    }
}));

function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500]
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired
};

export const ImageDialog = ({ item }) => {
    const [open, setOpen] = useState(false);
    const { uploadImageAward } = useRaffle();
    const [awardImage, setAwardImage] = useState();

    const saveAndClose = async (event) => {
        event.preventDefault();
        await uploadImageAward(item._id, awardImage);
        handleClose();
        // saveRaffle(form);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleFileDrop = async (fileData) => {
        // Aquí puedes enviar los datos del archivo a través de Axios
        try {
            // await uploadImageAward(item._id, fileData);
            setAwardImage(fileData);
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <>
            <Tooltip title="Cambiar imagen">
                <IconButton onClick={() => setOpen(true)} aria-label="Addaward" color="primary">
                    <Image />
                </IconButton>
            </Tooltip>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <form onSubmit={saveAndClose}>
                    <BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
                        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                            Update Image
                        </BootstrapDialogTitle>
                        <DialogContent dividers>
                            <DragAndDrop onDrop={handleFileDrop} />
                        </DialogContent>
                        <DialogActions>
                            <Button type="submit" onClick={saveAndClose}>
                                Save changes
                            </Button>
                        </DialogActions>
                    </BootstrapDialog>
                </form>
            </LocalizationProvider>
        </>
    );
};
