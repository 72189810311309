import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import Raffles from 'views/raffles';
import GuardedRoute from 'routes/GuardedRoute';
import { MPPayments } from 'views/mp';
import { Participants } from 'views/participants';
import { useAuthRef } from 'hooks/useAuth';
import Raffle from 'views/raffles/raffle';
import Products from 'views/product';
import ProductDetail from 'views/product/productDetail';
import Movements from 'views/movements';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));

// utilities routing
const UtilsTypography = Loadable(lazy(() => import('views/utilities/Typography')));
const UtilsColor = Loadable(lazy(() => import('views/utilities/Color')));
const UtilsShadow = Loadable(lazy(() => import('views/utilities/Shadow')));
const UtilsMaterialIcons = Loadable(lazy(() => import('views/utilities/MaterialIcons')));
const UtilsTablerIcons = Loadable(lazy(() => import('views/utilities/TablerIcons')));

// sample page routing
const SamplePage = Loadable(lazy(() => import('views/sample-page')));

// ==============================|| MAIN ROUTING ||============================== //

// const isRuteAuthenticated = () => {
//     const isAuthenticated = localStorage.getItem('auth_token') !== null ? true : false;
//     return;
// };
const isAuthenticated = () => localStorage.getItem('auth_token') !== null;

const MainRoutes = {
    path: '/',
    //element: <MainLayout />,
    element: <GuardedRoute component={MainLayout} auth={isAuthenticated} redirecTo="/login" />,
    children: [
        {
            path: '/',
            element: <DashboardDefault />
        },
        {
            path: 'dashboard',
            children: [
                {
                    path: 'default',
                    element: <DashboardDefault />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-typography',
                    element: <UtilsTypography />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-color',
                    element: <UtilsColor />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-shadow',
                    element: <UtilsShadow />
                }
            ]
        },
        {
            path: 'products',
            element: <Products />
        },
        {
            path: 'product',
            element: <ProductDetail />
        },
        {
            path: 'raffles',
            element: <Raffles />
        },
        {
            path: 'raffle',
            element: <Raffle />
        },
        {
            path: 'payments',
            element: <MPPayments />
        },
        {
            path: 'participants',
            element: <Participants />
        },
        {
            path: 'movements',
            element: <Movements />
        },
        {
            path: 'icons',
            children: [
                {
                    path: 'tabler-icons',
                    element: <UtilsTablerIcons />
                }
            ]
        },
        {
            path: 'icons',
            children: [
                {
                    path: 'material-icons',
                    element: <UtilsMaterialIcons />
                }
            ]
        },
        {
            path: 'sample-page',
            element: <SamplePage />
        }
    ]
};

export default MainRoutes;
