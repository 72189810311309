// material-ui
import { Tab, Tabs, Box, Badge } from '@mui/material';
import { useRaffle } from 'hooks/useRaffle';
import { useState } from 'react';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import { useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import { AwardList } from './AwardList';
import { RaffleDetail } from './RaffleDetail';
import { ParticipantsList } from './ParticipantsList';
import { Game } from './Game';
// ==============================|| SAMPLE PAGE ||============================== //

const Raffle = () => {
    const { raffle, getRaffle } = useRaffle();
    // const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [value, setValue] = useState(0);

    useEffect(() => {
        getRaffle(searchParams.get('id'));
    }, [searchParams]);

    const handleTab = (event, index) => {
        setValue(index);
    };

    const TabBody = (props) => {
        const { children, value, index, ...other } = props;
        return (
            <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`}>
                {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
            </div>
        );
    };

    return (
        <MainCard>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                    value={value}
                    onChange={handleTab}
                    textColor="secondary"
                    indicatorColor="secondary"
                    aria-label="basic tabs example"
                    variant="fullwidth"
                >
                    <Tab id="simple-tab-0" label="Raffle Detail" aria-controls="simple-tabpanel-0" />
                    <Tab id="simple-tab-1" label="Awards" aria-controls="simple-tabpanel-1" />
                    <Tab id="simple-tab-0" label="Participants" aria-controls="simple-tabpanel-2" />
                    <Tab id="simple-tab-1" label="Game" aria-controls="simple-tabpanel-3" />
                </Tabs>
            </Box>
            <TabBody value={value} index={0}>
                <RaffleDetail raffle={raffle} />
            </TabBody>
            <TabBody value={value} index={1}>
                <AwardList raffle={raffle} />
            </TabBody>
            <TabBody value={value} index={2}>
                <ParticipantsList raffle={raffle} />
            </TabBody>
            <TabBody value={value} index={3}>
                <Game raffle={raffle} />
            </TabBody>
        </MainCard>
    );
};

export default Raffle;
