// material-ui
import { Chip, IconButton, Paper, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material';
import { useRaffle } from 'hooks/useRaffle';
import { useState } from 'react';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import { CustomDialog } from 'ui-component/Dialog';
import { AddBox } from '@mui/icons-material';
import { useNavigate } from 'react-router';
import { useEffect } from 'react';
// ==============================|| SAMPLE PAGE ||============================== //

const Raffles = () => {
    const { raffles, getRaffles, addRaffle, updateRaffleFromSwitch, uploadImageRaffle } = useRaffle();
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();

    const actions = [{ label: 'Crear', icon: 'teast', onClick: () => setOpen(true) }];

    const handleUpdateSwitchValue = (item, attribute, value) => {
        const updateItem = {
            _id: item._id
        };
        updateItem[attribute] = value;
        updateRaffleFromSwitch(updateItem);
    };

    useEffect(() => {
        getRaffles();
    }, []);

    return (
        <MainCard title="Raffles" actions={actions}>
            <CustomDialog
                open={open}
                setOpen={setOpen}
                saveRaffle={addRaffle}
                uploadImageRaffle={uploadImageRaffle}
                getRaffles={getRaffles}
            />
            {raffles ? (
                <TableContainer component={Paper}>
                    {/* mejorar esto en otro componente */}
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Titulo</TableCell>
                                <TableCell align="right">Precio</TableCell>
                                <TableCell align="center">Imagen</TableCell>
                                <TableCell align="right">Estado</TableCell>
                                <TableCell align="right">Publico</TableCell>
                                <TableCell align="right">Habilitado</TableCell>
                                <TableCell align="right">Acciones</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {raffles.map((row) => (
                                <TableRow key={row.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell component="th" scope="row">
                                        {row.title}
                                    </TableCell>
                                    <TableCell align="right">${row.price}</TableCell>
                                    <TableCell align="center">
                                        <img src={row.image} alt="img" height={50} />
                                    </TableCell>
                                    <TableCell align="right">
                                        {row.awards.length !== 0 ? (
                                            <Chip style={{ color: 'white' }} label={`${row.awards.length} premios`} color={'success'} />
                                        ) : (
                                            <Chip style={{ color: 'grey' }} label={'Sin premios'} color={'warning'} />
                                        )}
                                    </TableCell>
                                    <TableCell align="right">
                                        <Switch
                                            color="primary"
                                            checked={row.isPublic}
                                            onChange={(e) => handleUpdateSwitchValue(row, 'isPublic', e.target.checked)}
                                            name="sdm"
                                        />
                                    </TableCell>
                                    <TableCell align="right">
                                        <Switch
                                            color="primary"
                                            checked={row.enabled}
                                            onChange={(e) => handleUpdateSwitchValue(row, 'enabled', e.target.checked)}
                                            name="sdm"
                                        />
                                    </TableCell>
                                    <TableCell align="right">
                                        <Tooltip title="Ver Detalles">
                                            <IconButton
                                                aria-label="Addaward"
                                                color="primary"
                                                onClick={() => navigate(`/raffle?id=${row._id}`)}
                                            >
                                                <AddBox />
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            ) : (
                <></>
            )}
        </MainCard>
    );
};

export default Raffles;
