import React from 'react';
import { useRaffle } from 'hooks/useRaffle';
import { useState } from 'react';
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    CardHeader,
    Button,
    Tooltip,
    IconButton
} from '@mui/material';
import { ImageDialog } from './ImageDialog';
import { CustomDialog } from './AwardDialog';
import { AddBox } from '@mui/icons-material';

export const AwardList = ({ raffle }) => {
    const [open, setOpen] = useState(false);
    const [selectedAward, setSelectedAward] = useState();
    const { setRaffle, addAward, uploadImageAward, updateAward } = useRaffle();

    const handleNewAward = async () => {
        await setSelectedAward();
        setOpen(true);
    };

    const handleAddRaffle = async (form, awardImage) => {
        if (selectedAward) {
            handleUpdateRaffle(form);
        } else {
            const newRaffle = { ...raffle };
            newRaffle.awards.push(form);
            setRaffle(newRaffle);
            const awardId = await addAward(raffle._id, form);
            if (awardImage) await uploadImageAward(awardId, awardImage);
        }
    };

    const handleUpdateRaffle = async (award) => {
        await updateAward(raffle._id, award.order, award);
    };

    const handleEditAward = (award) => {
        setSelectedAward(award);
        setOpen(true);
    };

    return (
        <>
            {/* open modal add award*/}
            <Paper elevation={0} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginRight: '1rem' }}>
                <CardHeader title="" />
                <div>
                    <CustomDialog award={selectedAward} open={open} setOpen={setOpen} saveRaffle={handleAddRaffle} />
                    <Button variant="contained" onClick={handleNewAward}>
                        Agregar
                    </Button>
                </div>
            </Paper>
            {/* awards table */}
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Titulo</TableCell>
                            <TableCell align="left">Descipción</TableCell>
                            <TableCell align="right">Posición</TableCell>
                            <TableCell align="right">Imagen</TableCell>
                            <TableCell align="right">Acción</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {raffle.awards.map((award, index) => (
                            <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell component="th" scope="row">
                                    {award.title}
                                </TableCell>
                                <TableCell align="left">{award.description}</TableCell>
                                <TableCell align="right">{award.order}</TableCell>
                                <TableCell align="right">
                                    <img src={award.image} alt="img" height={50} />
                                </TableCell>
                                <TableCell align="right" sx={{ display: 'flex', gap: '.1rem' }}>
                                    <ImageDialog key={`modal-${award.name}`} item={award} />
                                    <Tooltip title="Edit Award">
                                        <IconButton aria-label="editAward" color="primary" onClick={() => handleEditAward(award)}>
                                            <AddBox />
                                        </IconButton>
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};
