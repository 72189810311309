import { Description, Edit, Mail } from '@mui/icons-material';
import { Chip, IconButton, TableCell, TableRow, Tooltip } from '@mui/material';
import { useParticipant } from 'hooks/useParticipant';
import React from 'react';
import { useState } from 'react';
import { AddParticipantDialog } from './AddParticipantDialog';
import { UploadBillDialog } from './UploadBillDialog';

export const Payment = ({ row }) => {
    const { updateParticipant, uploadBill, sendMail } = useParticipant();
    const [open, setOpen] = useState(false);
    const [openUpload, setOpenUpload] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);

    const sendBill = () => {
        uploadBill(row.mp_payment_id, selectedFile);
    };

    return (
        <>
            <AddParticipantDialog open={open} setOpen={setOpen} saveParticipant={updateParticipant} row={row} />
            <UploadBillDialog
                open={openUpload}
                setOpen={setOpenUpload}
                setSelectedFile={setSelectedFile}
                selectedFile={selectedFile}
                sendBill={sendBill}
                row={row}
            />
            <TableRow key={row.mp_payment_id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row">
                    {row.mp_payment_id}
                </TableCell>
                <TableCell align="left">
                    {row.name} {row.lastName}
                </TableCell>
                <TableCell align="right">{row.email}</TableCell>
                <TableCell align="right">
                    <Chip label={row.isBilled ? 'Facturado' : 'Pendiente'} color={row.isBilled ? 'success' : 'warning'} />
                </TableCell>
                <TableCell align="right">
                    <Chip label={row.isMailSended ? 'Enviado' : 'Pendiente'} color={row.isMailSended ? 'success' : 'warning'} />
                </TableCell>
                <TableCell align="center">
                    <Tooltip title="Editar">
                        <IconButton onClick={() => setOpen(true)} aria-label="Addaward" color="primary">
                            <Edit />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Generar Factura">
                        <IconButton onClick={() => setOpenUpload(true)} aria-label="Addaward" color="primary" disabled={row.isBilled}>
                            <Description />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Enviar Mail">
                        <IconButton
                            onClick={() => sendMail(row.mp_payment_id)}
                            aria-label="Pagos"
                            color="primary"
                            disabled={row.isMailSended || !row.isBilled}
                        >
                            <Mail />
                        </IconButton>
                    </Tooltip>
                </TableCell>
            </TableRow>
        </>
    );
};
