import React from 'react';
import { Badge, Button, Container, Grid, TextField } from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useForm } from 'hooks/useForm';
import { useEffect } from 'react';
import dayjs from 'dayjs';
import { useRaffle } from 'hooks/useRaffle';
import { useNavigate } from 'react-router';
import DragAndDrop from 'ui-component/DragAndDrop';
import { useState } from 'react';
import ImageUploaderForm from 'ui-component/ImageUploaderForm';

export const RaffleDetail = ({ raffle }) => {
    const navigate = useNavigate();
    const [raffleImage, setRaffleImage] = useState();
    const { updateRaffleFromSwitch, notifyParticipants, uploadImageRaffle } = useRaffle();
    const { title, end_date, price, ticketsCost, image, description, minimumParticipants, form, onChangeForm, setForm } = useForm(
        raffle || {
            title: '',
            price: 0,
            ticketsCost: 1,
            minimumParticipants: 0,
            image: '',
            description: '',
            end_date: dayjs()
        }
    );

    const handleUpdate = async () => {
        await updateRaffleFromSwitch({
            _id: raffle._id,
            title,
            end_date,
            ticketsCost,
            minimumParticipants,
            price,
            image,
            description
        });
        await uploadImageRaffle(raffle._id, raffleImage);
        navigate(`/raffles`);
    };

    const onDrop = async (fileData) => {
        try {
            setRaffleImage(fileData);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        setForm(raffle);
    }, []);

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Container fixed>
                <ImageUploaderForm initialImage={raffle?.image} onFileChange={onDrop} />
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextField
                            id="title"
                            label="Title"
                            variant="outlined"
                            name="title"
                            fullWidth
                            value={title}
                            onChange={onChangeForm}
                        />
                    </Grid>
                    <Grid item xs={6} alignContent="center">
                        <Badge badgeContent={raffle?.participants?.length} color="error">
                            <Button
                                variant="contained"
                                onClick={() => notifyParticipants(raffle._id)}
                                disabled={raffle?.minimumParticipants == 0 || raffle?.minimumParticipants > raffle?.participants?.length}
                            >
                                Notificar a los participantes
                            </Button>
                        </Badge>
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            id="minimumParticipants"
                            label="Min. Participants"
                            variant="outlined"
                            name="minimumParticipants"
                            fullWidth
                            value={minimumParticipants}
                            onChange={onChangeForm}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <DesktopDatePicker
                            name="end_date"
                            label="End Date"
                            inputFormat="DD/MM/YYYY"
                            fullWidth
                            value={end_date}
                            onChange={(e) => onChangeForm({ target: { value: e, name: 'end_date' } })}
                            renderInput={(params) => <TextField fullWidth {...params} />}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            id="price"
                            label="Price"
                            variant="outlined"
                            name="price"
                            fullWidth
                            value={price}
                            onChange={onChangeForm}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            id="ticketsCost"
                            label="Tickets Cost"
                            variant="outlined"
                            name="ticketsCost"
                            fullWidth
                            value={ticketsCost}
                            onChange={onChangeForm}
                        />
                    </Grid>
                </Grid>
                <Grid item style={{ marginTop: 15, marginBottom: 15 }}>
                    <TextField
                        id="description"
                        label="Description"
                        variant="outlined"
                        name="description"
                        fullWidth
                        multiline
                        rows={3}
                        value={description}
                        onChange={onChangeForm}
                    />
                </Grid>
                <Grid
                    item
                    xs={4}
                    container
                    justifyContent="space-between"
                    style={{ marginTop: '20px', marginBottom: '25px', width: '100%' }}
                >
                    <Button variant="contained" onClick={handleUpdate}>
                        Guardar
                    </Button>
                    {/* <Button variant="contained" color="error" onClick={() => setOpen(true)}>
                        Eliminar
                    </Button> */}
                </Grid>
            </Container>
        </LocalizationProvider>
    );
};
