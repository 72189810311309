import { mpApi } from '../axios';

export const getAllPayments = (external_reference, range) => {
    const params = {
        sort: 'date_created',
        criteria: 'desc',
        status: 'approved'
    };
    if (external_reference) params['external_reference'] = external_reference;
    if (range) {
        params['begin_date'] = external_reference;
        params['end_date'] = external_reference;
    }
    return mpApi.get(`payments/search`, {
        params
    });
};
