import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
import MinimalLayout from 'layout/MinimalLayout';
import GuardedRoute from './GuardedRoute';

// login option 3 routing
const AuthLogin3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Login3')));
const AuthRegister3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Register3')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //
// TODO: Improve this
const isNotAuthenticated = () => {
    return !(localStorage.getItem('auth_token') !== null);
};

const AuthenticationRoutes = {
    path: '/',
    element: <GuardedRoute component={MinimalLayout} auth={isNotAuthenticated} redirecTo="/" />,
    children: [
        {
            path: '/login',
            element: <AuthLogin3 />
        },
        {
            path: '/register',
            element: <AuthRegister3 />
        }
    ]
};

export default AuthenticationRoutes;
