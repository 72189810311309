import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useForm } from 'hooks/useForm';
import { FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { useRaffle } from 'hooks/useRaffle';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2)
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1)
    }
}));

function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500]
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired
};

export const AddParticipantDialog = ({ open, setOpen, saveParticipant, row }) => {
    const { raffles } = useRaffle();
    const { mp_reference, name, lastName, email, mp_payment_id, payment_method, form, onChangeForm } = useForm(
        row
            ? { ...row }
            : {
                  mp_reference: '',
                  name: '',
                  lastName: '',
                  email: '',
                  mp_payment_id: '',
                  payment_method: 'CASH'
              }
    );

    const handleClose = () => {
        setOpen(false);
    };

    const saveAndClose = (event) => {
        event.preventDefault();
        const participant = { ...form };
        participant.description = raffles.find((raffle) => form.mp_reference === raffle.mp_reference).title;
        saveParticipant(participant);
        handleClose();
    };

    return (
        <form onSubmit={saveAndClose}>
            <BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                    New Raffle
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <TextField id="name" label="Nombre" variant="outlined" name="name" value={name} onChange={onChangeForm} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                id="lastName"
                                label="Apellido"
                                variant="outlined"
                                name="lastName"
                                value={lastName}
                                onChange={onChangeForm}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-select-small">MP References</InputLabel>
                                <Select
                                    labelId="demo-select-small"
                                    id="demo-select-small"
                                    name="mp_reference"
                                    value={mp_reference}
                                    label="Sorteo"
                                    onChange={onChangeForm}
                                    disabled={!!row}
                                >
                                    <MenuItem value="">None</MenuItem>
                                    {raffles.map((raffle) => (
                                        <MenuItem key={raffle.mp_reference} value={raffle.mp_reference}>
                                            {raffle.title}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                id="email"
                                label="E-mail"
                                variant="outlined"
                                name="email"
                                value={email}
                                onChange={onChangeForm}
                                disabled={!!row}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                id="mp_payment_id"
                                label="ID de pago"
                                variant="outlined"
                                name="mp_payment_id"
                                value={mp_payment_id}
                                onChange={onChangeForm}
                                disabled={!!row?.mp_payment_id}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth>
                                <InputLabel id="payment_method-select-small">MP References</InputLabel>
                                <Select
                                    labelId="payment_method-select-small"
                                    id="payment_method-select-small"
                                    name="payment_method"
                                    value={payment_method}
                                    label="Sorteo"
                                    onChange={onChangeForm}
                                    disabled={!!row}
                                >
                                    {['CASH', 'TRANSFER'].map((method) => (
                                        <MenuItem key={method} value={method}>
                                            {method}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button type="submit" onClick={saveAndClose}>
                        Guardar
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </form>
    );
};
