import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useForm } from 'hooks/useForm';
import { Grid, TextField } from '@mui/material';
import { useState } from 'react';
import DragAndDrop from './DragAndDrop';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2)
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1)
    }
}));

function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500]
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired
};

export const NewProductDialog = ({ open, setOpen, saveProduct, fetchProducts, uploadImageProduct }) => {
    const { name, price, description, tickets, discount, category, form, onChangeForm } = useForm({
        name: '',
        picture: '',
        tickets: 0,
        price: 0,
        discount: 0,
        category: '',
        description: ''
    });

    const [productImage, setProductImage] = useState();

    const handleClose = () => {
        setOpen(false);
    };

    const saveAndClose = async (event) => {
        event.preventDefault();
        const savedProduct = await saveProduct(form);
        await uploadImageProduct(savedProduct._id, productImage); //
        await fetchProducts();
        handleClose();
    };

    const onDrop = async (fileData) => {
        try {
            setProductImage(fileData);
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <form onSubmit={saveAndClose}>
            <BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                    New Product
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <TextField
                                id="name"
                                label="Name"
                                variant="outlined"
                                name="name"
                                fullWidth
                                value={name}
                                onChange={onChangeForm}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                id="price"
                                label="Price"
                                variant="outlined"
                                name="price"
                                fullWidth
                                value={price}
                                onChange={onChangeForm}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                id="tickets"
                                label="Tickets"
                                variant="outlined"
                                name="tickets"
                                fullWidth
                                value={tickets}
                                onChange={onChangeForm}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                id="discount"
                                label="Discount"
                                variant="outlined"
                                name="discount"
                                fullWidth
                                value={discount}
                                onChange={onChangeForm}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                id="category"
                                label="Category"
                                variant="outlined"
                                name="category"
                                fullWidth
                                value={category}
                                onChange={onChangeForm}
                            />
                        </Grid>
                        <Grid item xs={12} style={{ marginTop: 15, marginBottom: 15 }}>
                            <TextField
                                id="description"
                                label="Description"
                                variant="outlined"
                                name="description"
                                multiline
                                fullWidth
                                rows={4}
                                value={description}
                                onChange={onChangeForm}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <DragAndDrop onDrop={onDrop} />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button type="submit" onClick={saveAndClose}>
                        Save changes
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </form>
    );
};

NewProductDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    saveProduct: PropTypes.func.isRequired,
    fetchProducts: PropTypes.func.isRequired,
    uploadImageProduct: PropTypes.func.isRequired
};
