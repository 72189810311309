import { useState } from 'react';
import { getAllPayments } from 'services/Mp';

export const useMP = () => {
    const [payments, setPayments] = useState([]);

    const getPayments = async (extenalRef) => {
        try {
            if (extenalRef === '') return;
            const response = await getAllPayments(extenalRef);
            const data = await response.data;
            const payments = data.results
                .filter((payment) => {
                    return !payment.charges_details[0].refund_charges.length;
                })
                .map((payment) => {
                    return {
                        description: payment.description,
                        mp_reference: payment.external_reference,
                        mp_payment_id: payment.id,
                        email: payment.payer.email
                    };
                });
            setPayments(payments);
        } catch (error) {
            setPayments([]);
        }
    };

    return { payments, getPayments };
};
