import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useForm } from 'hooks/useForm';
import { Grid, TextField } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { useState } from 'react';
import DragAndDrop from './DragAndDrop';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2)
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1)
    }
}));

function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500]
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired
};

export const CustomDialog = ({ open, setOpen, saveRaffle, getRaffles, uploadImageRaffle }) => {
    const { title, end_date, price, description, form, ticketsCost, minimumParticipants, onChangeForm } = useForm({
        title: '',
        mp_reference: '',
        mp_link: 'mp-link',
        ticketsCost: 0,
        minimumParticipants: 0,
        price: 0,
        image: 'https://suerteo-image.com/img',
        description: '',
        end_date: dayjs()
    });

    const [raffleImage, setRaffleImage] = useState();

    const handleClose = () => {
        setOpen(false);
    };

    const saveAndClose = async (event) => {
        event.preventDefault();
        const raffleId = await saveRaffle(form);
        await uploadImageRaffle(raffleId, raffleImage); //
        await getRaffles();
        handleClose();
    };

    const onDrop = async (fileData) => {
        try {
            setRaffleImage(fileData);
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <form onSubmit={saveAndClose}>
                <BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
                    <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                        New Raffle
                    </BootstrapDialogTitle>
                    <DialogContent dividers>
                        <Grid item style={{ marginTop: 15, marginBottom: 15 }}>
                            <TextField
                                id="title"
                                label="Title"
                                variant="outlined"
                                name="title"
                                fullWidth
                                value={title}
                                onChange={onChangeForm}
                            />
                        </Grid>

                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <TextField
                                    id="minimumParticipants"
                                    label="Min. Participants"
                                    variant="outlined"
                                    name="minimumParticipants"
                                    fullWidth
                                    value={minimumParticipants}
                                    onChange={onChangeForm}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <DesktopDatePicker
                                    name="end_date"
                                    label="End Date"
                                    inputFormat="DD/MM/YYYY"
                                    fullWidth
                                    value={end_date}
                                    onChange={(e) => onChangeForm({ target: { value: e, name: 'end_date' } })}
                                    renderInput={(params) => <TextField fullWidth {...params} />}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    id="price"
                                    label="Price"
                                    variant="outlined"
                                    name="price"
                                    fullWidth
                                    value={price}
                                    onChange={onChangeForm}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    id="ticketsCost"
                                    label="Tickets Cost"
                                    variant="outlined"
                                    name="ticketsCost"
                                    fullWidth
                                    value={ticketsCost}
                                    onChange={onChangeForm}
                                />
                            </Grid>
                        </Grid>
                        <Grid>
                            <Grid item style={{ marginTop: 15, marginBottom: 15 }}>
                                <TextField
                                    id="description"
                                    label="Description"
                                    variant="outlined"
                                    name="description"
                                    multiline
                                    fullWidth
                                    rows={4}
                                    value={description}
                                    onChange={onChangeForm}
                                />
                            </Grid>
                            <Grid item>
                                <Grid item xs={6}>
                                    <DragAndDrop onDrop={onDrop} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button type="submit" onClick={saveAndClose}>
                            Save changes
                        </Button>
                    </DialogActions>
                </BootstrapDialog>
            </form>
        </LocalizationProvider>
    );
};
