import { Sync } from '@mui/icons-material';
import { CircularProgress, IconButton, TableCell, TableRow, Tooltip } from '@mui/material';
import { useParticipant } from 'hooks/useParticipant';
import React from 'react';

export const Payment = ({ row }) => {
    const { isLoading, participant, syncParticipant } = useParticipant(row.mp_payment_id);

    const handleSync = () => {
        const participant = { ...row };
        participant.payment_method = 'MP_LINK';
        syncParticipant(participant);
    };

    return (
        <TableRow key={row.mp_payment_id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
                {row.mp_payment_id}
            </TableCell>
            <TableCell align="right">{row.email}</TableCell>
            <TableCell align="center">
                {isLoading ? (
                    <CircularProgress size={20} />
                ) : participant ? (
                    <span>Participando</span>
                ) : (
                    <Tooltip title="Sincronizar">
                        <IconButton aria-label="Addaward" color="primary" onClick={handleSync}>
                            <Sync />
                        </IconButton>
                    </Tooltip>
                )}
            </TableCell>
        </TableRow>
    );
};
