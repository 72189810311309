import { api } from '../axios';

export const getParticipant = (mp_payment_id) => {
    return api.get(`/participant`, { params: { mp_payment_id } });
};

export const sendMailApi = (mp_payment_id) => {
    return api.get(`/participant/send-mail`, { params: { mp_payment_id } });
};

export const getParticipants = (mp_reference) => {
    return api.get(`/participants`, { params: { mp_reference } });
};

export const uploadBillApi = (mp_payment_id, billFile) => {
    const formData = new FormData();
    formData.append('billFile', billFile);
    return api.post(`/participant/make-bill`, formData, {
        params: {
            mp_payment_id
        },
        headers: { 'Content-Type': 'multipart/form-data' }
    });
};

export const syncParticipantApi = (body) => {
    return api.post(`/participant/sync`, body);
};

export const updateParticipantApi = (body) => {
    return api.put(`/participant`, body);
};
