import { createContext, useState } from 'react';

export const RaffleContext = createContext({
    raffles: []
});

export const RaffleProvider = ({ children }) => {
    const [raffles, setRaffles] = useState([]);
    const [raffle, setRaffle] = useState({});
    return <RaffleContext.Provider value={{ raffles, setRaffles, raffle, setRaffle }}>{children}</RaffleContext.Provider>;
};
