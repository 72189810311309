// assets
import { IconDashboard, IconTicket, IconCurrencyDollarSingapore, IconUser, IconReceipt2, IconBox } from '@tabler/icons';

// constant
const icons = { IconDashboard, IconTicket, IconCurrencyDollarSingapore, IconUser, IconReceipt2, IconBox };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
    id: 'dashboard',
    title: 'Dashboard',
    type: 'group',
    children: [
        {
            id: 'default',
            title: 'Dashboard',
            type: 'item',
            url: '/dashboard/default',
            icon: icons.IconDashboard,
            breadcrumbs: false
        },
        {
            id: 'raffle',
            title: 'Raffles',
            type: 'item',
            url: '/raffles',
            icon: icons.IconTicket,
            breadcrumbs: false
        },
        {
            id: 'products',
            title: 'Products',
            type: 'item',
            url: '/products',
            icon: icons.IconBox,
            breadcrumbs: false
        },
        {
            id: 'movements',
            title: 'Movements',
            type: 'item',
            url: '/movements',
            icon: icons.IconReceipt2,
            breadcrumbs: false
        },
        {
            id: 'payment',
            title: 'Payments',
            type: 'item',
            url: '/payments',
            icon: icons.IconReceipt2,
            breadcrumbs: false
        },
        {
            id: 'participants',
            title: 'Participants',
            type: 'item',
            url: '/Participants',
            icon: icons.IconUser,
            breadcrumbs: false
        }
    ]
};

export default dashboard;
