import React from 'react';
import Box from '@mui/material/Box';

const Banner = ({ imageSrc }) => {
    return (
        <Box
            sx={{
                width: '100%',
                height: '200px',
                backgroundImage: `url(${imageSrc})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                borderRadius: '8px',
                boxShadow: 1,
                marginBottom: '20px'
            }}
        />
    );
};

export default Banner;
