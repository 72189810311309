import { signInService, setTokenStorageService, clearTokenStorage, getTokenStorageService } from 'services/Auth';
import { useNavigate } from 'react-router-dom';

export const useAuthRef = () => {
    const navigate = useNavigate();
    const signIn = (user) => {
        signInService(user).then((res) => {
            setTokenStorageService(res.data.token);
            navigate('/raffles');
        });
    };

    const logOut = () => {
        clearTokenStorage();
        navigate('/login');
    };

    const isAuthenticated = () => {
        return getTokenStorageService() !== null;
    };

    return {
        signIn,
        logOut,
        isAuthenticated
    };
};
