import { useTransition, useSpring, useChain, config, animated, useSpringRef } from '@react-spring/web';
import React, { useEffect } from 'react';
import { useState } from 'react';
import './RandomnizeStyle.css';
import { IconSquareX } from '@tabler/icons';

export const Randomnize = ({ data, setData, mixer }) => {
    const [open, set] = useState(false);
    const springApi = useSpringRef();
    const transApi = useSpringRef();

    useEffect(() => {
        set((open) => !open);
        mixer.current = mixerItems;
    }, []);

    const { size, ...rest } = useSpring({
        ref: springApi,
        config: config.stiff,
        from: { size: '20%', background: 'white' },
        to: {
            size: open ? '100%' : '20%',
            background: open ? 'white' : 'white'
        }
    });

    const transition = useTransition(open ? data : [], {
        ref: transApi,
        trail: 400 / data.length,
        from: { opacity: 0, scale: 0 },
        enter: { opacity: 1, scale: 1 },
        leave: { opacity: 0, scale: 0 }
    });

    useChain(open ? [springApi, transApi] : [transApi, springApi], [0, open ? 0.1 : 0.6]);

    const mixerItems = () => {
        setData((values) => {
            const aux = [...values];
            for (let i = aux.length - 1; i > 0; i--) {
                const j = Math.floor(Math.random() * (i + 1));
                [aux[i], aux[j]] = [aux[j], aux[i]];
            }
            return aux;
        });
    };

    const remove = (index) => {
        const aux = [...data];
        aux.splice(index, 1);
        setData([...aux]);
    };

    return (
        <>
            <div className="wrapper">
                <animated.div style={{ ...rest, width: size, height: size }} className="container">
                    {transition((style, item, ___, index) => (
                        <animated.div
                            style={{
                                ...style,
                                height: 100,
                                borderRadius: 5,
                                color: !item.winner ? 'white' : 'grey',
                                background: !item.winner ? item.color : '#eaf6f6',
                                opacity: 3,
                                font: 'bold'
                            }}
                        >
                            {item.winner ? (
                                <div style={baseStyle.close}>
                                    {/* eslint-disable-next-line */}
                                    <IconSquareX onClick={() => remove(index)} />
                                </div>
                            ) : (
                                <></>
                            )}
                            <div style={baseStyle.center}>
                                <span>@{item.userName}</span>
                            </div>
                        </animated.div>
                    ))}
                </animated.div>
            </div>
        </>
    );
};

const baseStyle = {
    center: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 100
    },
    close: {
        float: 'right',
        padding: 6,
        cursor: 'pointer',
        font: 'bold',
        fontSize: 16
    }
};
